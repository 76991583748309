import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import useMediaQuery from '@mui/material/useMediaQuery';
import LoadingComponent from '../components/LoadingComponent';

import { ThemeProvider } from '@mui/material/styles';
import { createNewTheme } from '../functions/utils';
import { Box, Button, Card, CardActionArea, CardContent, CardHeader, CardMedia, Chip, Divider, Grid, List, ListItem, ListItemButton, ListItemText, Tab, Tabs, TextField, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import { Carousel } from 'react-responsive-carousel';
// import Grid from '@mui/material/Grid2';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { AccessTime, AttachMoneyOutlined, Check, CloudCircleOutlined, CloudQueueOutlined, CloudSyncOutlined, CloudUploadOutlined, FacebookOutlined, Instagram, LinkedIn, SensorOccupiedOutlined, SentimentVerySatisfiedOutlined, SmartToyOutlined } from '@mui/icons-material';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Discador({ onChange, update, colorPrimary, colorSecondary, colorTertiary }, props) {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [loading, setLoading] = useState(false);

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <LoadingComponent open={loading} />

      <div style={{ height: 100 }} />
      <Typography style={{ textAlign: "center", fontFamily: "roboto regular", fontSize: 64, fontWeight: "bold", color: "#2B3AE7" }}>
        Discador
      </Typography>

      <Typography style={{ fontFamily: "roboto light", textAlign: "center", fontSize: 33, color: "#ABACBA" }}>
        Potencialize a produtividade no Call Center
      </Typography>

      <Typography style={{ fontFamily: "roboto light", textAlign: "center" }}>
        <div style={{ height: 16 }} />
        <Button variant={"outlined"} onClick={handleOpenLoading} size="large" style={{ textTransform: 'none', margin: "auto", fontFamily: "roboto light", fontWeight: "bold" }}>
          Solicitar demonstração
        </Button>
      </Typography>

      <div style={{ height: 4 }} />
      <div style={{ padding: 26 }}>

        <div style={{ width: "100%", overflowX: "auto", display: "flex", whiteSpace: "nowrap" }}>
          <div style={{ display: "inline-block", minWidth: 290, width: "100%", marginRight: "16px" }}>
            <Card variant="outlined" sx={{ borderRadius: "8px", background: "#FFFFFF00" }}>
              <CardActionArea style={{ padding: 16 }}>
                <Typography style={{ textAlign: "center" }}>
                  <CloudUploadOutlined style={{ fontSize: 70, color: "#2B3AE7" }} />
                </Typography>
                <CardContent style={{ textAlign: "left", padding: 0 }}>

                  <Typography style={{ color: "#575975", fontFamily: "roboto regular", display: "flex", alignItems: "center", wordWrap: "break-word", whiteSpace: "-moz-pre-wrap", whiteSpace: "pre-wrap" }}>
                    O Discador ELY é uma solução completa que transforma operações de discagem, seja local ou na nuvem.
                  </Typography>
                </CardContent>

              </CardActionArea>
            </Card>
          </div>

          <div style={{ display: "inline-block", minWidth: 290, width: "100%", marginRight: "16px" }}>
            <Card variant="outlined" sx={{ borderRadius: "8px", background: "#FFFFFF00" }}>
              <CardActionArea style={{ padding: 16 }}>

                <Typography style={{ textAlign: "center" }}>
                  <SmartToyOutlined style={{ fontSize: 70, color: "#2B3AE7" }} />
                </Typography>
                <CardContent style={{ textAlign: "left", padding: 0 }}>

                  <Typography style={{ color: "#575975", fontFamily: "roboto regular", display: "flex", alignItems: "center", wordWrap: "break-word", whiteSpace: "-moz-pre-wrap", whiteSpace: "pre-wrap" }}>
                    Ele opera nos modos Preview, Power e Preditivo, integrando-se a um portal de voz com URAs e voicebots avançados.
                  </Typography>
                </CardContent>

              </CardActionArea>
            </Card>
          </div>

          <div style={{ display: "inline-block", minWidth: 290, width: "100%", paddingBottom: 22 }}>
            <Card variant="outlined" sx={{ borderRadius: "8px", background: "#FFFFFF00" }}>
              <CardActionArea style={{ padding: 16 }}>

                <Typography style={{ textAlign: "center" }}>
                  <CloudSyncOutlined style={{ fontSize: 70, color: "#2B3AE7" }} />
                </Typography>

                <CardContent style={{ textAlign: "left", padding: 0 }}>

                  <Typography style={{ color: "#575975", fontFamily: "roboto regular", display: "flex", alignItems: "center", wordWrap: "break-word", whiteSpace: "-moz-pre-wrap", whiteSpace: "pre-wrap" }}>
                    Aumente a eficiência, produtividade e automação no atendimento com essa tecnologia inovadora.
                  </Typography>
                </CardContent>

              </CardActionArea>
            </Card>
          </div>
        </div>

        <div style={{ height: 40 }} />

        <Typography style={{ fontFamily: "roboto light", textAlign: "center", fontSize: 33, color: "#ABACBA", fontWeight: "bold" }}>
          Por que escolher nosso discador?
        </Typography>

        <div style={{ height: 16 }} />

        <Grid container spacing={2} style={{ padding: 0 }}>
          <Grid item xs={12} sm={12} md={4} style={{ paddingTop: 0 }}>
            <div style={{ border: "solid 1px #ccc", display: "flex", alignItems: "center", padding: 16, borderRadius: 8, marginTop: 16 }}>
              <CloudCircleOutlined style={{ color: "#2B3AE7" }} /> &nbsp;&nbsp;&nbsp;
              <b style={{ fontFamily: "roboto light", textAlign: "center", color: "#999" }}>Automação Completa</b>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={4} style={{ paddingTop: 0 }}>
            <div style={{ border: "solid 1px #ccc", display: "flex", alignItems: "center", padding: 16, borderRadius: 8, marginTop: 16 }}>
              <SensorOccupiedOutlined style={{ color: "#2B3AE7" }} />&nbsp;&nbsp;&nbsp;
              <b style={{ fontFamily: "roboto light", textAlign: "center", color: "#999" }}>Integração Avançada</b>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={4} style={{ paddingTop: 0 }}>
            <div style={{ border: "solid 1px #ccc", display: "flex", alignItems: "center", padding: 16, borderRadius: 8, marginTop: 16 }}>
              <CloudQueueOutlined style={{ color: "#2B3AE7" }} />&nbsp;&nbsp;&nbsp;
              <b style={{ fontFamily: "roboto light", textAlign: "center", color: "#999" }}>Operação em Nuvem</b>
            </div>
          </Grid>
        </Grid>
        <div style={{ height: 80 }} />

        <Typography style={{ fontFamily: "roboto light", textAlign: "center", fontSize: 33, color: "#ABACBA", fontWeight: "bold" }}>
          Recursos
        </Typography>

        <div style={{ height: 24 }} />

        <Grid container spacing={2} style={{ padding: 0 }}>

          <Grid item xs={12} sm={12} md={12} style={{ paddingTop: 0 }}>
            <Chip
              style={{ fontSize: 18, background: "#2B3AE7", color: "#fff", padding: 20, margin: 5, borderRadius: 8 }}
              label={<Typography style={{ display: "flex", alignItems: "center" }}>Onsite ou remot &nbsp;&nbsp;<Check style={{ color: "#fff", marginTop: -4 }} /></Typography>}
            />

            <Chip
              style={{ fontSize: 18, background: "#2B3AE7", color: "#fff", padding: 20, margin: 5, borderRadius: 8 }}
              label={<Typography style={{ display: "flex", alignItems: "center" }}>WhatsApp integrado &nbsp;&nbsp;<Check style={{ color: "#fff", marginTop: -4 }} /></Typography>}
            />

            <Chip
              style={{ fontSize: 18, background: "#2B3AE7", color: "#fff", padding: 20, margin: 5, borderRadius: 8 }}
              label={<Typography style={{ display: "flex", alignItems: "center" }}>Gestão de mailings &nbsp;&nbsp;<Check style={{ color: "#fff", marginTop: -4 }} /></Typography>}
            />

            <Chip
              style={{ fontSize: 18, background: "#2B3AE7", color: "#fff", padding: 20, margin: 5, borderRadius: 8 }}
              label={<Typography style={{ display: "flex", alignItems: "center" }}>Detecção de voicemail &nbsp;&nbsp;<Check style={{ color: "#fff", marginTop: -4 }} /></Typography>}
            />

            <Chip
              style={{ fontSize: 18, background: "#2B3AE7", color: "#fff", padding: 20, margin: 5, borderRadius: 8 }}
              label={<Typography style={{ display: "flex", alignItems: "center" }}>Softphone web &nbsp;&nbsp;<Check style={{ color: "#fff", marginTop: -4 }} /></Typography>}
            />
          </Grid>
        </Grid>

        <div style={{ height: 80 }} />

        <div style={{ background: "#E3E3E8", borderRadius: 24 }}>
          <Grid container spacing={2} style={{ padding: 32 }}>
            <Grid item xs={12} sm={12} md={6} style={{ paddingTop: 0 }}>
              <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
                <Typography style={{ color: "#2B3AE7", fontFamily: "roboto regular", fontSize: 32, fontWeight: "bold" }}>
                  Entre em contato conosco
                  <div style={{ height: 6 }} />
                  <b style={{ fontWeight: "normal", fontSize: 22, color: "#1c204a" }}>Preencha as informações e entraremos em contato com você!</b>
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} style={{ paddingTop: 0 }}>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                    Nome <b style={{ color: "#A51D1D" }}>*</b>
                  </Typography>
                  <div style={{ height: 4 }}></div>
                  <TextField
                    fullWidth
                    hiddenLabel
                    // value={nome}
                    // onChange={handleNome}
                    variant="outlined"
                    size="large"
                    placeholder="Insira o seu nome"
                    sx={{ background: "#fff" }}
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                    E-mail <b style={{ color: "#A51D1D" }}>*</b>
                  </Typography>
                  <div style={{ height: 4 }}></div>
                  <TextField
                    fullWidth
                    hiddenLabel
                    // value={nome}
                    // onChange={handleNome}
                    variant="outlined"
                    size="large"
                    placeholder="Insira o seu e-mail"
                    sx={{ background: "#fff" }}
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                    Mensagem <b style={{ color: "#A51D1D" }}>*</b>
                  </Typography>
                  <div style={{ height: 4 }}></div>
                  <TextField
                    fullWidth
                    hiddenLabel
                    // value={nome}
                    // onChange={handleNome}
                    variant="outlined"
                    size="large"
                    placeholder="Insira sua mensagem aqui..."
                    sx={{ background: "#fff" }}
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Button variant={"contained"} size="large" style={{ textTransform: 'none', margin: "auto", fontFamily: "roboto light", fontWeight: "bold", minWidth: 140 }}>
                    Enviar
                  </Button>
                </Grid>
              </Grid>


            </Grid>
          </Grid>
        </div>

        <div style={{ height: 80 }} />
      </div>

      <div style={{ background: "#1C204A" }}>
        <Grid container spacing={2} style={{ padding: 32 }}>
          <Grid item xs={12} sm={12} md={3} style={{ paddingTop: 0 }}>
            <img src="https://elysolucoestecnologicas.com.br/images/ely-logo-white.png" style={{ width: 200, marginLeft: "auto" }} />
          </Grid>
          <Grid item xs={12} sm={12} md={4} style={{ paddingTop: 0 }}>
            <Typography style={{ color: "#fff", fontFamily: "roboto light", display: "flex", alignItems: "center", height: "100%" }}>
              Ely Soluções Tecnológicas LTDA
              CNPJ 43.363.160.0001-08
              <br /><br />
              @2021-2023 Todos os Direitos Reservados
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={5} style={{ paddingTop: 0 }}>
            <Typography style={{ color: "#fff", fontFamily: "roboto light", display: "flex", alignItems: "center", height: "100%" }}>
              <label><b>Informações</b><br /><br />
                <b>Endereço</b><br />Travessa Dona Paula, 13, Higienópolis
                São Paulo-SP, 01239-050
                <br /><br />
                <b>Contato</b><br />relacionamento@elysolucoestecnologicas.com.br
              </label>
            </Typography>
          </Grid>
        </Grid>
      </div>

    </ThemeProvider >
  );
}

export default Discador;