import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import useMediaQuery from '@mui/material/useMediaQuery';
import LoadingComponent from '../components/LoadingComponent';

import { ThemeProvider } from '@mui/material/styles';
import { createNewTheme } from '../functions/utils';
import { Box, Button, Card, CardActionArea, CardContent, CardHeader, CardMedia, Divider, Grid, List, ListItem, ListItemButton, ListItemText, Tab, Tabs, TextField, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import { Carousel } from 'react-responsive-carousel';
// import Grid from '@mui/material/Grid2';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { CloudSyncOutlined, CloudUploadOutlined, DnsOutlined, FacebookOutlined, GroupsOutlined, InsightsOutlined, Instagram, LinkedIn, SentimentVerySatisfiedOutlined, SupportAgentOutlined } from '@mui/icons-material';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function SobreNos({ onChange, update, colorPrimary, colorSecondary, colorTertiary }, props) {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [loading, setLoading] = useState(false);

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <LoadingComponent open={loading} />

      <div style={{ height: 100 }} />
      <Typography style={{ textAlign: "center", fontFamily: "roboto regular", fontSize: 64, fontWeight: "bold", color: "#2B3AE7" }}>
        Sobre nós
      </Typography>

      <Typography style={{ fontFamily: "roboto light", textAlign: "center", fontSize: 33, color: "#ABACBA" }}>
        Transformamos a experiência de atendimento e gestão com<br />soluções tecnológicas inovadoras.
      </Typography>

      <div style={{ height: 24 }} />
      <div style={{ padding: 26 }}>
        <div style={{
          width: "100%",
          height: "500px",
          borderRadius: 12,
          backgroundImage: "url('https://elysolucoestecnologicas.com.br/images/bg-2.png')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }} />

        <div style={{ height: 36 }} />

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs variant="scrollable" value={value} onChange={handleChange} allowScrollButtonsMobile>
            <Tab label="Missão" {...a11yProps(0)} style={{ textTransform: "none", minWidth: 150 }} />
            <Tab label="Visão" {...a11yProps(1)} style={{ textTransform: "none", minWidth: 150 }} />
            <Tab label="Valores" {...a11yProps(2)} style={{ textTransform: "none", minWidth: 150 }} />
          </Tabs>
        </Box>

        <div style={{ height: 36 }} />

        {value === 0 &&
          <>
            <div style={{ width: "100%", overflowX: "auto", display: "flex", whiteSpace: "nowrap", paddingBottom: 22 }}>
              <div style={{ display: "inline-block", minWidth: 290, maxWidth: 290, marginRight: "16px" }}>
                <Card variant="outlined" sx={{ borderRadius: "8px", background: "#FFFFFF00" }}>
                  <CardActionArea style={{ padding: 16 }}>
                    <Typography style={{ textAlign: "center" }}>
                      <SupportAgentOutlined style={{ fontSize: 70, color: "#2B3AE7" }} />
                    </Typography>
                    <CardContent style={{ textAlign: "left", padding: 0 }}>

                      <div style={{ height: 2 }} />

                      <Typography style={{ color: "#585a75", fontFamily: "roboto regular", fontWeight: "bold", display: "flex", alignItems: "center", wordWrap: "break-word", whiteSpace: "-moz-pre-wrap", whiteSpace: "pre-wrap" }}>
                        Otimização de Atendimento
                      </Typography>

                      <Typography style={{ color: "#ABACBA", fontFamily: "roboto regular", fontWeight: "normal", display: "flex", alignItems: "center", wordWrap: "break-word", whiteSpace: "-moz-pre-wrap", whiteSpace: "pre-wrap" }}>
                        Automação inteligente para um suporte mais ágil, personalizado e de qualidade.
                      </Typography>
                      <div style={{ height: 16 }} />

                    </CardContent>
                  </CardActionArea>
                </Card>
              </div>

              <div style={{ display: "inline-block", minWidth: 290, maxWidth: 290, marginRight: "16px" }}>
                <Card variant="outlined" sx={{ borderRadius: "8px", background: "#FFFFFF00" }}>
                  <CardActionArea style={{ padding: 16 }}>
                    <Typography style={{ textAlign: "center" }}>
                      <DnsOutlined style={{ fontSize: 70, color: "#2B3AE7" }} />
                    </Typography>

                    <CardContent style={{ textAlign: "left", padding: 0 }}>

                      <div style={{ height: 2 }} />

                      <Typography style={{ color: "#585a75", fontFamily: "roboto regular", fontWeight: "bold", display: "flex", alignItems: "center", wordWrap: "break-word", whiteSpace: "-moz-pre-wrap", whiteSpace: "pre-wrap" }}>
                        Virtualização Completa
                      </Typography>

                      <Typography style={{ color: "#ABACBA", fontFamily: "roboto regular", fontWeight: "normal", display: "flex", alignItems: "center", wordWrap: "break-word", whiteSpace: "-moz-pre-wrap", whiteSpace: "pre-wrap" }}>
                        Virtualizamos call centers e operações, reduzindo custos e aumentando a eficiência.
                      </Typography>
                      <div style={{ height: 16 }} />

                    </CardContent>
                  </CardActionArea>
                </Card>
              </div>
            </div>
          </>
        }

        {value === 1 &&
          <>
            <div style={{ width: "100%", overflowX: "auto", display: "flex", whiteSpace: "nowrap", paddingBottom: 22 }}>
              <div style={{ display: "inline-block", minWidth: 290, maxWidth: 290, marginRight: "16px" }}>
                <Card variant="outlined" sx={{ borderRadius: "8px", background: "#FFFFFF00" }}>
                  <CardActionArea style={{ padding: 16 }}>
                    <Typography style={{ textAlign: "center" }}>
                      <CloudUploadOutlined style={{ fontSize: 70, color: "#2B3AE7" }} />
                    </Typography>

                    <CardContent style={{ textAlign: "left", padding: 0 }}>

                      <div style={{ height: 2 }} />

                      <Typography style={{ color: "#585a75", fontFamily: "roboto regular", fontWeight: "bold", display: "flex", alignItems: "center", wordWrap: "break-word", whiteSpace: "-moz-pre-wrap", whiteSpace: "pre-wrap" }}>
                        Liderança em Tecnologia
                      </Typography>

                      <Typography style={{ color: "#ABACBA", fontFamily: "roboto regular", fontWeight: "normal", display: "flex", alignItems: "center", wordWrap: "break-word", whiteSpace: "-moz-pre-wrap", whiteSpace: "pre-wrap" }}>
                        Ser referência em soluções de automação e virtualização de atendimento.
                      </Typography>
                      <div style={{ height: 16 }} />

                    </CardContent>
                  </CardActionArea>
                </Card>
              </div>

              <div style={{ display: "inline-block", minWidth: 290, maxWidth: 290, marginRight: "16px" }}>
                <Card variant="outlined" sx={{ borderRadius: "8px", background: "#FFFFFF00" }}>
                  <CardActionArea style={{ padding: 16 }}>
                    <Typography style={{ textAlign: "center" }}>
                      <InsightsOutlined style={{ fontSize: 70, color: "#2B3AE7" }} />
                    </Typography>

                    <CardContent style={{ textAlign: "left", padding: 0 }}>

                      <div style={{ height: 2 }} />

                      <Typography style={{ color: "#585a75", fontFamily: "roboto regular", fontWeight: "bold", display: "flex", alignItems: "center", wordWrap: "break-word", whiteSpace: "-moz-pre-wrap", whiteSpace: "pre-wrap" }}>
                        Inovação Constante
                      </Typography>

                      <Typography style={{ color: "#ABACBA", fontFamily: "roboto regular", fontWeight: "normal", display: "flex", alignItems: "center", wordWrap: "break-word", whiteSpace: "-moz-pre-wrap", whiteSpace: "pre-wrap" }}>
                        Evoluir com soluções disruptivas para melhorar a experiência do cliente.
                      </Typography>
                      <div style={{ height: 16 }} />

                    </CardContent>
                  </CardActionArea>
                </Card>
              </div>
            </div>
          </>
        }

        {value === 2 &&
          <>
            <div style={{ width: "100%", overflowX: "auto", display: "flex", whiteSpace: "nowrap", paddingBottom: 22 }}>
              <div style={{ display: "inline-block", minWidth: 290, maxWidth: 290, marginRight: "16px" }}>
                <Card variant="outlined" sx={{ borderRadius: "8px", background: "#FFFFFF00" }}>
                  <CardActionArea style={{ padding: 16 }}>
                    <Typography style={{ textAlign: "center" }}>
                      <SentimentVerySatisfiedOutlined style={{ fontSize: 70, color: "#2B3AE7" }} />
                    </Typography>
                    <CardContent style={{ textAlign: "left", padding: 0 }}>

                      <div style={{ height: 2 }} />

                      <Typography style={{ color: "#585a75", fontFamily: "roboto regular", fontWeight: "bold", display: "flex", alignItems: "center", wordWrap: "break-word", whiteSpace: "-moz-pre-wrap", whiteSpace: "pre-wrap" }}>
                        Foco no Cliente
                      </Typography>

                      <Typography style={{ color: "#ABACBA", fontFamily: "roboto regular", fontWeight: "normal", display: "flex", alignItems: "center", wordWrap: "break-word", whiteSpace: "-moz-pre-wrap", whiteSpace: "pre-wrap" }}>
                        Sempre priorizar a satisfação, confiança e o sucesso dos nossos parceiros.
                      </Typography>
                      <div style={{ height: 16 }} />

                    </CardContent>
                  </CardActionArea>
                </Card>
              </div>

              <div style={{ display: "inline-block", minWidth: 290, maxWidth: 290, marginRight: "16px" }}>
                <Card variant="outlined" sx={{ borderRadius: "8px", background: "#FFFFFF00" }}>
                  <CardActionArea style={{ padding: 16 }}>
                    <Typography style={{ textAlign: "center" }}>
                      <CloudSyncOutlined style={{ fontSize: 70, color: "#2B3AE7" }} />
                    </Typography>
                    <CardContent style={{ textAlign: "left", padding: 0 }}>

                      <div style={{ height: 2 }} />

                      <Typography style={{ color: "#585a75", fontFamily: "roboto regular", fontWeight: "bold", display: "flex", alignItems: "center", wordWrap: "break-word", whiteSpace: "-moz-pre-wrap", whiteSpace: "pre-wrap" }}>
                        Inovação e Eficiência
                      </Typography>

                      <Typography style={{ color: "#ABACBA", fontFamily: "roboto regular", fontWeight: "normal", display: "flex", alignItems: "center", wordWrap: "break-word", whiteSpace: "-moz-pre-wrap", whiteSpace: "pre-wrap" }}>
                        Buscar constantemente novas maneiras de otimizar processos com tecnologia.
                      </Typography>
                      <div style={{ height: 16 }} />

                    </CardContent>
                  </CardActionArea>
                </Card>
              </div>

              <div style={{ display: "inline-block", minWidth: 290, maxWidth: 290, marginRight: "16px" }}>
                <Card variant="outlined" sx={{ borderRadius: "8px", background: "#FFFFFF00" }}>
                  <CardActionArea style={{ padding: 16 }}>
                    <Typography style={{ textAlign: "center" }}>
                      <GroupsOutlined style={{ fontSize: 70, color: "#2B3AE7" }} />
                    </Typography>
                    <CardContent style={{ textAlign: "left", padding: 0 }}>

                      <div style={{ height: 2 }} />

                      <Typography style={{ color: "#585a75", fontFamily: "roboto regular", fontWeight: "bold", display: "flex", alignItems: "center", wordWrap: "break-word", whiteSpace: "-moz-pre-wrap", whiteSpace: "pre-wrap" }}>
                        Transparência
                      </Typography>

                      <Typography style={{ color: "#ABACBA", fontFamily: "roboto regular", fontWeight: "normal", display: "flex", alignItems: "center", wordWrap: "break-word", whiteSpace: "-moz-pre-wrap", whiteSpace: "pre-wrap" }}>
                        Comprometimento com ética e clareza em todas as ações de nossa equipe.
                      </Typography>
                      <div style={{ height: 16 }} />

                    </CardContent>
                  </CardActionArea>
                </Card>
              </div>
            </div>
          </>
        }

        <div style={{ height: 78 }} />

        <div style={{ background: "#F6F6F9", borderRadius: 24 }}>
          <Grid container spacing={2} style={{ padding: 32 }}>
            <Grid item xs={12} sm={12} md={6} style={{ paddingTop: 0 }}>
              <Typography style={{ color: "#1C204A", fontFamily: "roboto regular", fontSize: 32, fontWeight: "bold", display: "flex", height: "100%", alignItems: "center" }}>
                Siga-nos em nossas redes sociais
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} style={{ paddingTop: 0 }}>

              <Button variant={"contained"} fullWidth={isMobile} disableElevation size="large" style={{ minWidth: 150, margin: 8, marginLeft: isMobile ? 0 : "", textTransform: 'none', color: "#2B3AE7", background: "#E3E3E8", fontFamily: "roboto light", fontWeight: "bold" }} startIcon={<Instagram />}>
                Instagram
              </Button>

              <Button variant={"contained"} fullWidth={isMobile} disableElevation size="large" style={{ minWidth: 150, margin: 8, marginLeft: isMobile ? 0 : "", textTransform: 'none', color: "#2B3AE7", background: "#E3E3E8", fontFamily: "roboto light", fontWeight: "bold" }} startIcon={<FacebookOutlined />}>
                Facebook
              </Button>

              <Button variant={"contained"} fullWidth={isMobile} disableElevation size="large" style={{ minWidth: 150, margin: 8, marginLeft: isMobile ? 0 : "", textTransform: 'none', color: "#2B3AE7", background: "#E3E3E8", fontFamily: "roboto light", fontWeight: "bold" }} startIcon={<LinkedIn />}>
                Linkedin
              </Button>

            </Grid>
          </Grid>
        </div>
        <div style={{ height: 80 }} />
      </div>

      <div style={{ background: "#1C204A" }}>
        <Grid container spacing={2} style={{ padding: 32 }}>
          <Grid item xs={12} sm={12} md={3} style={{ paddingTop: 0 }}>
            <img src="https://elysolucoestecnologicas.com.br/images/ely-logo-white.png" style={{ width: 200, marginLeft: "auto" }} />
          </Grid>
          <Grid item xs={12} sm={12} md={4} style={{ paddingTop: 0 }}>
            <Typography style={{ color: "#fff", fontFamily: "roboto light", display: "flex", alignItems: "center", height: "100%" }}>
              Ely Soluções Tecnológicas LTDA
              CNPJ 43.363.160.0001-08
              <br /><br />
              @2021-2023 Todos os Direitos Reservados
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={5} style={{ paddingTop: 0 }}>
            <Typography style={{ color: "#fff", fontFamily: "roboto light", display: "flex", alignItems: "center", height: "100%" }}>
              <label><b>Informações</b><br /><br />
                <b>Endereço</b><br />Travessa Dona Paula, 13, Higienópolis
                São Paulo-SP, 01239-050
                <br /><br />
                <b>Contato</b><br />relacionamento@elysolucoestecnologicas.com.br
              </label>
            </Typography>
          </Grid>
        </Grid>
      </div>

    </ThemeProvider >
  );
}

export default SobreNos;