import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import useMediaQuery from '@mui/material/useMediaQuery';
import LoadingComponent from '../components/LoadingComponent';

import { ThemeProvider } from '@mui/material/styles';
import { createNewTheme } from '../functions/utils';
import { Box, Button, Card, CardActionArea, CardContent, CardHeader, CardMedia, Divider, Grid, List, ListItem, ListItemButton, ListItemText, Tab, Tabs, TextField, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import { Carousel } from 'react-responsive-carousel';
// import Grid from '@mui/material/Grid2';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function HomeWebsite({ onChange, update, colorPrimary, colorSecondary, colorTertiary }, props) {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [loading, setLoading] = useState(false);

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <LoadingComponent open={loading} />

      <div style={{ height: 100 }} />
      <Typography style={{ textAlign: "center", fontFamily: "roboto regular", fontSize: 64, fontWeight: "bold", color: "#2B3AE7" }}>
        Transforme seu atendimento
      </Typography>

      <Typography style={{ fontFamily: "roboto light", textAlign: "center", fontSize: 33, color: "#ABACBA" }}>
        Automatize, integre e inove com nossas<br />
        soluções tecnológicas!
      </Typography>

      <Typography style={{ fontFamily: "roboto light", textAlign: "center" }}>
        <div style={{ height: 16 }} />
        <Button variant={"outlined"} onClick={handleOpenLoading} size="large" style={{ textTransform: 'none', margin: "auto", fontFamily: "roboto light", fontWeight: "bold" }}>
          Solicitar demonstração
        </Button>
      </Typography>

      <div style={{ height: 24 }} />
      <div style={{ padding: 26 }}>
        <div style={{
          width: "100%",
          height: "500px",
          borderRadius: 12,
          backgroundImage: "url('https://elysolucoestecnologicas.com.br/images/bg-1.png')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }} />

        <div style={{ height: 36 }} />

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs variant="scrollable" value={value} onChange={handleChange} allowScrollButtonsMobile>
            <Tab label="Soluções" {...a11yProps(0)} style={{ textTransform: "none", minWidth: 150 }} />
            <Tab label="Serviços" {...a11yProps(1)} style={{ textTransform: "none", minWidth: 150 }} />
            <Tab label="Nuvem" {...a11yProps(2)} style={{ textTransform: "none", minWidth: 150 }} />
          </Tabs>
        </Box>

        <div style={{ height: 36 }} />

        {value === 0 &&
          <>
            <div style={{ width: "100%", overflowX: "auto", display: "flex", whiteSpace: "nowrap", paddingBottom: 22 }}>
              <div style={{ display: "inline-block", minWidth: 290, maxWidth: 290, marginRight: "16px" }}>
                <Card variant="outlined" sx={{ borderRadius: "8px", background: "#FFFFFF00" }}>
                  <CardActionArea style={{ padding: 16 }}>
                    <CardMedia component="img" height="170" style={{ borderRadius: 8 }} image={`https://elysolucoestecnologicas.com.br/images/sl-1.png`} onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = "https://dedicado.mely.online/imagens/placeholder.png";
                    }} />
                    <CardContent style={{ textAlign: "left", padding: 0 }}>

                      <div style={{ height: 16 }} />

                      <Typography style={{ color: "#585a75", fontFamily: "roboto regular", fontWeight: "bold", display: "flex", alignItems: "center", wordWrap: "break-word", whiteSpace: "-moz-pre-wrap", whiteSpace: "pre-wrap" }}>
                        Call center virtual
                      </Typography>

                      <Typography style={{ color: "#ABACBA", fontFamily: "roboto regular", fontWeight: "normal", display: "flex", alignItems: "center", wordWrap: "break-word", whiteSpace: "-moz-pre-wrap", whiteSpace: "pre-wrap" }}>Transforme seu call center com tecnologia avançada, permitindo operações remotas e eficientes.</Typography>
                      <div style={{ height: 16 }} />

                      <Button variant={"outlined"} fullWidth size="large" style={{ textTransform: 'none', fontFamily: "roboto light", fontWeight: "bold" }}>
                        Entre em contato
                      </Button>

                    </CardContent>
                  </CardActionArea>
                </Card>
              </div>

              <div style={{ display: "inline-block", minWidth: 290, maxWidth: 290, marginRight: "16px" }}>
                <Card variant="outlined" sx={{ borderRadius: "8px", background: "#FFFFFF00" }}>
                  <CardActionArea style={{ padding: 16 }}>
                    <CardMedia component="img" height="170" style={{ borderRadius: 8 }} image={`https://elysolucoestecnologicas.com.br/images/sl-2.png`} onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = "https://dedicado.mely.online/imagens/placeholder.png";
                    }} />
                    <CardContent style={{ textAlign: "left", padding: 0 }}>

                      <div style={{ height: 16 }} />

                      <Typography style={{ color: "#585a75", fontFamily: "roboto regular", fontWeight: "bold", display: "flex", alignItems: "center", wordWrap: "break-word", whiteSpace: "-moz-pre-wrap", whiteSpace: "pre-wrap" }}>
                        Atendimento automatizado
                      </Typography>

                      <Typography style={{ color: "#ABACBA", fontFamily: "roboto regular", fontWeight: "normal", display: "flex", alignItems: "center", wordWrap: "break-word", whiteSpace: "-moz-pre-wrap", whiteSpace: "pre-wrap" }}>
                        Automatize processos de atendimento para melhorar eficiência e satisfação.
                      </Typography>
                      <div style={{ height: 16 }} />

                      <Button variant={"outlined"} fullWidth size="large" style={{ textTransform: 'none', fontFamily: "roboto light", fontWeight: "bold" }}>
                        Entre em contato
                      </Button>

                    </CardContent>
                  </CardActionArea>
                </Card>
              </div>

              <div style={{ display: "inline-block", minWidth: 290, maxWidth: 290, marginRight: "16px" }}>
                <Card variant="outlined" sx={{ borderRadius: "8px", background: "#FFFFFF00" }}>
                  <CardActionArea style={{ padding: 16 }}>
                    <CardMedia component="img" height="170" style={{ borderRadius: 8 }} image={`https://elysolucoestecnologicas.com.br/images/sl-3.png`} onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = "https://dedicado.mely.online/imagens/placeholder.png";
                    }} />
                    <CardContent style={{ textAlign: "left", padding: 0 }}>

                      <div style={{ height: 16 }} />

                      <Typography style={{ color: "#585a75", fontFamily: "roboto regular", fontWeight: "bold", display: "flex", alignItems: "center", wordWrap: "break-word", whiteSpace: "-moz-pre-wrap", whiteSpace: "pre-wrap" }}>
                        PABX Asterisk completo
                      </Typography>

                      <Typography style={{ color: "#ABACBA", fontFamily: "roboto regular", fontWeight: "normal", display: "flex", alignItems: "center", wordWrap: "break-word", whiteSpace: "-moz-pre-wrap", whiteSpace: "pre-wrap" }}>
                        Configure PABX Asterisk com suporte técnico e manutenção contínua.
                      </Typography>
                      <div style={{ height: 16 }} />

                      <Button variant={"outlined"} fullWidth size="large" style={{ textTransform: 'none', fontFamily: "roboto light", fontWeight: "bold" }}>
                        Entre em contato
                      </Button>

                    </CardContent>
                  </CardActionArea>
                </Card>
              </div>

              <div style={{ display: "inline-block", minWidth: 290, maxWidth: 290, marginRight: "16px" }}>
                <Card variant="outlined" sx={{ borderRadius: "8px", background: "#FFFFFF00" }}>
                  <CardActionArea style={{ padding: 16 }}>
                    <CardMedia component="img" height="170" style={{ borderRadius: 8 }} image={`https://elysolucoestecnologicas.com.br/images/sl-4.png`} onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = "https://dedicado.mely.online/imagens/placeholder.png";
                    }} />
                    <CardContent style={{ textAlign: "left", padding: 0 }}>

                      <div style={{ height: 16 }} />

                      <Typography style={{ color: "#585a75", fontFamily: "roboto regular", fontWeight: "bold", display: "flex", alignItems: "center", wordWrap: "break-word", whiteSpace: "-moz-pre-wrap", whiteSpace: "pre-wrap" }}>
                        Atendimento humanizado
                      </Typography>

                      <Typography style={{ color: "#ABACBA", fontFamily: "roboto regular", fontWeight: "normal", display: "flex", alignItems: "center", wordWrap: "break-word", whiteSpace: "-moz-pre-wrap", whiteSpace: "pre-wrap" }}>
                        Melhore seu atendimento eletrônico, tornando-o mais humano e acessível.
                      </Typography>
                      <div style={{ height: 16 }} />

                      <Button variant={"outlined"} fullWidth size="large" style={{ textTransform: 'none', fontFamily: "roboto light", fontWeight: "bold" }}>
                        Entre em contato
                      </Button>

                    </CardContent>
                  </CardActionArea>
                </Card>
              </div>

              <div style={{ display: "inline-block", minWidth: 290, maxWidth: 290, marginRight: "16px" }}>
                <Card variant="outlined" sx={{ borderRadius: "8px", background: "#FFFFFF00" }}>
                  <CardActionArea style={{ padding: 16 }}>
                    <CardMedia component="img" height="170" style={{ borderRadius: 8 }} image={`https://elysolucoestecnologicas.com.br/images/sl-5.png`} onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = "https://dedicado.mely.online/imagens/placeholder.png";
                    }} />
                    <CardContent style={{ textAlign: "left", padding: 0 }}>

                      <div style={{ height: 16 }} />

                      <Typography style={{ color: "#585a75", fontFamily: "roboto regular", fontWeight: "bold", display: "flex", alignItems: "center", wordWrap: "break-word", whiteSpace: "-moz-pre-wrap", whiteSpace: "pre-wrap" }}>
                        Omnichannel customizado
                      </Typography>

                      <Typography style={{ color: "#ABACBA", fontFamily: "roboto regular", fontWeight: "normal", display: "flex", alignItems: "center", wordWrap: "break-word", whiteSpace: "-moz-pre-wrap", whiteSpace: "pre-wrap" }}>
                        Personalize sua plataforma Omnichannel para integração eficiente e otimizada.
                      </Typography>
                      <div style={{ height: 16 }} />

                      <Button variant={"outlined"} fullWidth size="large" style={{ textTransform: 'none', fontFamily: "roboto light", fontWeight: "bold" }}>
                        Entre em contato
                      </Button>

                    </CardContent>
                  </CardActionArea>
                </Card>
              </div>

              <div style={{ display: "inline-block", minWidth: 290, maxWidth: 290, marginRight: "16px" }}>
                <Card variant="outlined" sx={{ borderRadius: "8px", background: "#FFFFFF00" }}>
                  <CardActionArea style={{ padding: 16 }}>
                    <CardMedia component="img" height="170" style={{ borderRadius: 8 }} image={`https://elysolucoestecnologicas.com.br/images/sl-6.png`} onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = "https://dedicado.mely.online/imagens/placeholder.png";
                    }} />
                    <CardContent style={{ textAlign: "left", padding: 0 }}>

                      <div style={{ height: 16 }} />

                      <Typography style={{ color: "#585a75", fontFamily: "roboto regular", fontWeight: "bold", display: "flex", alignItems: "center", wordWrap: "break-word", whiteSpace: "-moz-pre-wrap", whiteSpace: "pre-wrap" }}>
                        Aplicações URA
                      </Typography>

                      <Typography style={{ color: "#ABACBA", fontFamily: "roboto regular", fontWeight: "normal", display: "flex", alignItems: "center", wordWrap: "break-word", whiteSpace: "-moz-pre-wrap", whiteSpace: "pre-wrap" }}>
                        Automatize seu atendimento com aplicações URA personalizadas e inteligentes.
                      </Typography>
                      <div style={{ height: 16 }} />

                      <Button variant={"outlined"} fullWidth size="large" style={{ textTransform: 'none', fontFamily: "roboto light", fontWeight: "bold" }}>
                        Entre em contato
                      </Button>

                    </CardContent>
                  </CardActionArea>
                </Card>
              </div>
            </div>
          </>
        }

        {value === 1 &&
          <>
            <div style={{ width: "100%", overflowX: "auto", display: "flex", whiteSpace: "nowrap", paddingBottom: 22 }}>
              <div style={{ display: "inline-block", minWidth: 290, maxWidth: 290, marginRight: "16px" }}>
                <Card variant="outlined" sx={{ borderRadius: "8px", background: "#FFFFFF00" }}>
                  <CardActionArea style={{ padding: 16 }}>
                    <CardMedia component="img" height="170" style={{ borderRadius: 8 }} image={`https://elysolucoestecnologicas.com.br/images/sv-1.png`} onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = "https://dedicado.mely.online/imagens/placeholder.png";
                    }} />
                    <CardContent style={{ textAlign: "left", padding: 0 }}>

                      <div style={{ height: 16 }} />

                      <Typography style={{ color: "#585a75", fontFamily: "roboto regular", fontWeight: "bold", display: "flex", alignItems: "center", wordWrap: "break-word", whiteSpace: "-moz-pre-wrap", whiteSpace: "pre-wrap" }}>
                        Agente Virtual
                      </Typography>

                      <Typography style={{ color: "#ABACBA", fontFamily: "roboto regular", fontWeight: "normal", display: "flex", alignItems: "center", wordWrap: "break-word", whiteSpace: "-moz-pre-wrap", whiteSpace: "pre-wrap" }}>
                        Automatize interações seu próprio agente virtual eficiente, inteligente e intuitivo.
                      </Typography>
                      <div style={{ height: 16 }} />

                      <Button variant={"outlined"} fullWidth size="large" style={{ textTransform: 'none', fontFamily: "roboto light", fontWeight: "bold" }}>
                        Entre em contato
                      </Button>

                    </CardContent>
                  </CardActionArea>
                </Card>
              </div>

              <div style={{ display: "inline-block", minWidth: 290, maxWidth: 290, marginRight: "16px" }}>
                <Card variant="outlined" sx={{ borderRadius: "8px", background: "#FFFFFF00" }}>
                  <CardActionArea style={{ padding: 16 }}>
                    <CardMedia component="img" height="170" style={{ borderRadius: 8 }} image={`https://elysolucoestecnologicas.com.br/images/sv-2.png`} onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = "https://dedicado.mely.online/imagens/placeholder.png";
                    }} />
                    <CardContent style={{ textAlign: "left", padding: 0 }}>

                      <div style={{ height: 16 }} />

                      <Typography style={{ color: "#585a75", fontFamily: "roboto regular", fontWeight: "bold", display: "flex", alignItems: "center", wordWrap: "break-word", whiteSpace: "-moz-pre-wrap", whiteSpace: "pre-wrap" }}>
                        Omnichannel
                      </Typography>

                      <Typography style={{ color: "#ABACBA", fontFamily: "roboto regular", fontWeight: "normal", display: "flex", alignItems: "center", wordWrap: "break-word", whiteSpace: "-moz-pre-wrap", whiteSpace: "pre-wrap" }}>
                        Unifique todos os seus canais de atendimento em uma só plataforma.
                      </Typography>
                      <div style={{ height: 16 }} />

                      <Button variant={"outlined"} fullWidth size="large" style={{ textTransform: 'none', fontFamily: "roboto light", fontWeight: "bold" }}>
                        Entre em contato
                      </Button>

                    </CardContent>
                  </CardActionArea>
                </Card>
              </div>

              <div style={{ display: "inline-block", minWidth: 290, maxWidth: 290, marginRight: "16px" }}>
                <Card variant="outlined" sx={{ borderRadius: "8px", background: "#FFFFFF00" }}>
                  <CardActionArea style={{ padding: 16 }}>
                    <CardMedia component="img" height="170" style={{ borderRadius: 8 }} image={`https://elysolucoestecnologicas.com.br/images/sv-3.png`} onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = "https://dedicado.mely.online/imagens/placeholder.png";
                    }} />
                    <CardContent style={{ textAlign: "left", padding: 0 }}>

                      <div style={{ height: 16 }} />

                      <Typography style={{ color: "#585a75", fontFamily: "roboto regular", fontWeight: "bold", display: "flex", alignItems: "center", wordWrap: "break-word", whiteSpace: "-moz-pre-wrap", whiteSpace: "pre-wrap" }}>
                        Discador
                      </Typography>

                      <Typography style={{ color: "#ABACBA", fontFamily: "roboto regular", fontWeight: "normal", display: "flex", alignItems: "center", wordWrap: "break-word", whiteSpace: "-moz-pre-wrap", whiteSpace: "pre-wrap" }}>
                        Reduza custos operacionais e obtenha aumento exponencial dos resultados de sua equipe
                      </Typography>
                      <div style={{ height: 16 }} />

                      <Button variant={"outlined"} fullWidth size="large" style={{ textTransform: 'none', fontFamily: "roboto light", fontWeight: "bold" }}>
                        Entre em contato
                      </Button>

                    </CardContent>
                  </CardActionArea>
                </Card>
              </div>
            </div>
          </>
        }

        {value === 2 &&
          <>
            <div style={{ width: "100%", overflowX: "auto", display: "flex", whiteSpace: "nowrap", paddingBottom: 22 }}>
              <div style={{ display: "inline-block", minWidth: 290, maxWidth: 290, marginRight: "16px" }}>
                <Card variant="outlined" sx={{ borderRadius: "8px", background: "#FFFFFF00" }}>
                  <CardActionArea style={{ padding: 16 }}>
                    <CardMedia component="img" height="170" style={{ borderRadius: 8 }} image={`https://elysolucoestecnologicas.com.br/images/nv-1.png`} onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = "https://dedicado.mely.online/imagens/placeholder.png";
                    }} />
                    <CardContent style={{ textAlign: "left", padding: 0 }}>

                      <div style={{ height: 16 }} />

                      <Typography style={{ color: "#585a75", fontFamily: "roboto regular", fontWeight: "bold", display: "flex", alignItems: "center", wordWrap: "break-word", whiteSpace: "-moz-pre-wrap", whiteSpace: "pre-wrap" }}>
                        Segurança total
                      </Typography>

                      <Typography style={{ color: "#ABACBA", fontFamily: "roboto regular", fontWeight: "normal", display: "flex", alignItems: "center", wordWrap: "break-word", whiteSpace: "-moz-pre-wrap", whiteSpace: "pre-wrap" }}>
                        Proteja seus dados com soluções de segurança em nuvem de alto nível.
                      </Typography>
                      <div style={{ height: 16 }} />

                      <Button variant={"outlined"} fullWidth size="large" style={{ textTransform: 'none', fontFamily: "roboto light", fontWeight: "bold" }}>
                        Entre em contato
                      </Button>

                    </CardContent>
                  </CardActionArea>
                </Card>
              </div>

              <div style={{ display: "inline-block", minWidth: 290, maxWidth: 290, marginRight: "16px" }}>
                <Card variant="outlined" sx={{ borderRadius: "8px", background: "#FFFFFF00" }}>
                  <CardActionArea style={{ padding: 16 }}>
                    <CardMedia component="img" height="170" style={{ borderRadius: 8 }} image={`https://elysolucoestecnologicas.com.br/images/nv-2.png`} onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = "https://dedicado.mely.online/imagens/placeholder.png";
                    }} />
                    <CardContent style={{ textAlign: "left", padding: 0 }}>

                      <div style={{ height: 16 }} />

                      <Typography style={{ color: "#585a75", fontFamily: "roboto regular", fontWeight: "bold", display: "flex", alignItems: "center", wordWrap: "break-word", whiteSpace: "-moz-pre-wrap", whiteSpace: "pre-wrap" }}>
                        Servidores sem espaço físico
                      </Typography>

                      <Typography style={{ color: "#ABACBA", fontFamily: "roboto regular", fontWeight: "normal", display: "flex", alignItems: "center", wordWrap: "break-word", whiteSpace: "-moz-pre-wrap", whiteSpace: "pre-wrap" }}>
                        Aproveite a nuvem e elimine totalmente servidores físicos na sua empresa.
                      </Typography>
                      <div style={{ height: 16 }} />

                      <Button variant={"outlined"} fullWidth size="large" style={{ textTransform: 'none', fontFamily: "roboto light", fontWeight: "bold" }}>
                        Entre em contato
                      </Button>

                    </CardContent>
                  </CardActionArea>
                </Card>
              </div>

              <div style={{ display: "inline-block", minWidth: 290, maxWidth: 290, marginRight: "16px" }}>
                <Card variant="outlined" sx={{ borderRadius: "8px", background: "#FFFFFF00" }}>
                  <CardActionArea style={{ padding: 16 }}>
                    <CardMedia component="img" height="170" style={{ borderRadius: 8 }} image={`https://elysolucoestecnologicas.com.br/images/nv-3.png`} onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = "https://dedicado.mely.online/imagens/placeholder.png";
                    }} />
                    <CardContent style={{ textAlign: "left", padding: 0 }}>

                      <div style={{ height: 16 }} />

                      <Typography style={{ color: "#585a75", fontFamily: "roboto regular", fontWeight: "bold", display: "flex", alignItems: "center", wordWrap: "break-word", whiteSpace: "-moz-pre-wrap", whiteSpace: "pre-wrap" }}>
                        Manutenção de servidores
                      </Typography>

                      <Typography style={{ color: "#ABACBA", fontFamily: "roboto regular", fontWeight: "normal", display: "flex", alignItems: "center", wordWrap: "break-word", whiteSpace: "-moz-pre-wrap", whiteSpace: "pre-wrap" }}>
                        Reduza manutenções e suporte físico com nossas soluções em nuvem.
                      </Typography>
                      <div style={{ height: 16 }} />

                      <Button variant={"outlined"} fullWidth size="large" style={{ textTransform: 'none', fontFamily: "roboto light", fontWeight: "bold" }}>
                        Entre em contato
                      </Button>

                    </CardContent>
                  </CardActionArea>
                </Card>
              </div>

              <div style={{ display: "inline-block", minWidth: 290, maxWidth: 290, marginRight: "16px" }}>
                <Card variant="outlined" sx={{ borderRadius: "8px", background: "#FFFFFF00" }}>
                  <CardActionArea style={{ padding: 16 }}>
                    <CardMedia component="img" height="170" style={{ borderRadius: 8 }} image={`https://elysolucoestecnologicas.com.br/images/nv-4.png`} onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = "https://dedicado.mely.online/imagens/placeholder.png";
                    }} />
                    <CardContent style={{ textAlign: "left", padding: 0 }}>

                      <div style={{ height: 16 }} />

                      <Typography style={{ color: "#585a75", fontFamily: "roboto regular", fontWeight: "bold", display: "flex", alignItems: "center", wordWrap: "break-word", whiteSpace: "-moz-pre-wrap", whiteSpace: "pre-wrap" }}>
                        Data centers redundantes
                      </Typography>

                      <Typography style={{ color: "#ABACBA", fontFamily: "roboto regular", fontWeight: "normal", display: "flex", alignItems: "center", wordWrap: "break-word", whiteSpace: "-moz-pre-wrap", whiteSpace: "pre-wrap" }}>
                        Garanta disponibilidade contínua com data centers redundantes e seguros.
                      </Typography>
                      <div style={{ height: 16 }} />

                      <Button variant={"outlined"} fullWidth size="large" style={{ textTransform: 'none', fontFamily: "roboto light", fontWeight: "bold" }}>
                        Entre em contato
                      </Button>

                    </CardContent>
                  </CardActionArea>
                </Card>
              </div>
            </div>
          </>
        }

        <div style={{ height: 78 }} />

        <div style={{ background: "#E3E3E8", borderRadius: 24 }}>
          <Grid container spacing={2} style={{ padding: 32 }}>
            <Grid item xs={12} sm={12} md={6} style={{ paddingTop: 0 }}>
              <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
                <Typography style={{ color: "#2B3AE7", fontFamily: "roboto regular", fontSize: 32, fontWeight: "bold" }}>
                  Entre em contato conosco
                  <div style={{ height: 6 }} />
                  <b style={{ fontWeight: "normal", fontSize: 22, color: "#1c204a" }}>Preencha as informações e entraremos em contato com você!</b>
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} style={{ paddingTop: 0 }}>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                    Nome <b style={{ color: "#A51D1D" }}>*</b>
                  </Typography>
                  <div style={{ height: 4 }}></div>
                  <TextField
                    fullWidth
                    hiddenLabel
                    // value={nome}
                    // onChange={handleNome}
                    variant="outlined"
                    size="large"
                    placeholder="Insira o seu nome"
                    sx={{ background: "#fff" }}
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                    E-mail <b style={{ color: "#A51D1D" }}>*</b>
                  </Typography>
                  <div style={{ height: 4 }}></div>
                  <TextField
                    fullWidth
                    hiddenLabel
                    // value={nome}
                    // onChange={handleNome}
                    variant="outlined"
                    size="large"
                    placeholder="Insira o seu e-mail"
                    sx={{ background: "#fff" }}
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                    Mensagem <b style={{ color: "#A51D1D" }}>*</b>
                  </Typography>
                  <div style={{ height: 4 }}></div>
                  <TextField
                    fullWidth
                    hiddenLabel
                    // value={nome}
                    // onChange={handleNome}
                    variant="outlined"
                    size="large"
                    placeholder="Insira sua mensagem aqui..."
                    sx={{ background: "#fff" }}
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Button variant={"contained"} size="large" style={{ textTransform: 'none', margin: "auto", fontFamily: "roboto light", fontWeight: "bold", minWidth: 140 }}>
                    Enviar
                  </Button>
                </Grid>
              </Grid>


            </Grid>
          </Grid>
        </div>
        <div style={{ height: 80 }} />
      </div>

      <div style={{ background: "#1C204A" }}>
        <Grid container spacing={2} style={{ padding: 32 }}>
          <Grid item xs={12} sm={12} md={3} style={{ paddingTop: 0 }}>
            <img src="https://elysolucoestecnologicas.com.br/images/ely-logo-white.png" style={{ width: 200, marginLeft: "auto" }} />
          </Grid>
          <Grid item xs={12} sm={12} md={4} style={{ paddingTop: 0 }}>
            <Typography style={{ color: "#fff", fontFamily: "roboto light", display: "flex", alignItems: "center", height: "100%" }}>
              Ely Soluções Tecnológicas LTDA
              CNPJ 43.363.160.0001-08
              <br /><br />
              @2021-2023 Todos os Direitos Reservados
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={5} style={{ paddingTop: 0 }}>
            <Typography style={{ color: "#fff", fontFamily: "roboto light", display: "flex", alignItems: "center", height: "100%" }}>
              <label><b>Informações</b><br /><br />
                <b>Endereço</b><br />Travessa Dona Paula, 13, Higienópolis
                São Paulo-SP, 01239-050
                <br /><br />
                <b>Contato</b><br />relacionamento@elysolucoestecnologicas.com.br
              </label>
            </Typography>
          </Grid>
        </Grid>
      </div>

    </ThemeProvider >
  );
}

export default HomeWebsite;