import { Alert, AppBar, Avatar, Box, Button, Divider, Fade, FormControl, IconButton, InputAdornment, ListItemButton, ListItemIcon, Menu, MenuItem, Modal, Select, TextField, Toolbar, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState, useEffect, useRef } from 'react';
import { AnalyticsOutlined, ArticleOutlined, BrowserUpdatedOutlined, CalculateOutlined, Close, CloudDownloadOutlined, ContactSupportOutlined, ContentCopy, CurrencyExchangeOutlined, DeliveryDiningOutlined, DensityMediumOutlined, EditOutlined, ExpandMoreOutlined, FormatPaintOutlined, GroupsOutlined, HistoryOutlined, HomeOutlined, InventoryOutlined, LayersOutlined, ListAltOutlined, LoginOutlined, MenuOutlined, MenuRounded, MopedOutlined, MoveDownOutlined, PhoneOutlined, PointOfSaleOutlined, PrintOutlined, QrCodeOutlined, RestaurantOutlined, RoomServiceOutlined, Search, SentimentDissatisfiedOutlined, SettingsOutlined, ShareOutlined, StoreOutlined, StorefrontOutlined, TableBarOutlined, VolumeOffOutlined, VolumeUpOutlined } from '@mui/icons-material';
import { formatarHorarioCompleto, stringAvatar } from '../functions/utils';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Cookies from 'js-cookie';
import axios from 'axios';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: "8px",
};

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `none`,
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    flexDirection: 'reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: 0,
    paddingLeft: 0,
    marginTop: -4,
    borderRadius: 6,
    backgroundColor: "#f8f8f8",
}));

const SideMenu = ({ onChange, update, colorPrimary }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();

    const [statusLoja, setStatusLoja] = useState("aberto");
    const [impressora, setImpressora] = useState("");
    const [volume, setVolume] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const [noAccess, setNoAccess] = useState(false);
    const [modalPdv, setModalPdv] = useState(false);
    const [isEstoqueMinimo, setIsEstoqueMinimo] = useState(false);
    const [currentPage, setCurrentPage] = useState("home");
    const [urlBase, setUrlBase] = useState("");
    const [tempoEntrega, setTempoEntrega] = useState("");
    const [alerts, setAlerts] = useState([]);
    const [dataAtual, setDataAtual] = useState(new Date());
    const [colorPrimaryS, setColorPrimary] = useState(false);
    const [planoMely, setPlanoMely] = useState("");
    const [loadingPdv, setLoadingPdv] = useState(false);
    const [progress, setProgress] = useState(0);
    const [isRunning, setIsRunning] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [isPdv, setIsPdv] = React.useState(false);
    const [retiradaNovos, setRetiradaNovos] = React.useState(0);
    const [mesasNovos, setMesasNovas] = React.useState(0);
    const [deliveryNovos, setDeliveryNovos] = React.useState(0);

    const intervalIdRef = useRef(null);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const openSolucoes = Boolean(anchorEl);
    const handleOpenSolucoes = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseSolucoes = () => {
        setAnchorEl(null);
    };

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const handleOpenModal = () => {
        setOpenModal(true);
        setModalPdv(false)
    };
    const handleCloseModal = () => {
        setNoAccess(false);
        setOpenModal(false);
    };

    const handleShare = () => {
        handleOpenModal()
    }

    const handleStatus = (event) => {
        setStatusLoja(event.target.value)
    }

    const changePage = (page) => {
        handleCloseSolucoes()
        onChange("", page);
        setCurrentPage(page);
    }

    useEffect(() => {
        getPlanoUsuario()

        const impressora = Cookies.get("IAt") !== "false";
        setImpressora(impressora)

        const audio = Cookies.get("Aud") !== "false";
        setVolume(audio)

        setIsPdv(Cookies.get("iSpA") == "true")
        const timer = setInterval(() => {
            updateNotifications();
            setDataAtual(new Date());
        }, 1000);

        return () => clearInterval(timer);

    }, []);

    const getPlanoUsuario = () => {
        axios.post(`https://mely.online/api/plano.php`, {
            email: Cookies.get("emEst"),
        })
            .then(response => {
                response = response.data
                if (response) {
                    setPlanoMely(response[0].PN_ML)
                }
            })
    }


    const updateNotifications = () => {
        const mesasNovas = Cookies.get("msNv") ? Cookies.get("msNv") : 0;
        const deliveryNovos = Cookies.get("dlNv") ? Cookies.get("dlNv") : 0;
        const retiradaNovos = Cookies.get("rtNv") ? Cookies.get("rtNv") : 0;
        setMesasNovas(mesasNovas)
        setDeliveryNovos(deliveryNovos)
        setRetiradaNovos(retiradaNovos)
    }

    const getHorarios = (url, mainId) => {
        axios.post(`${url}/horarios.php`, {
            idestabelecimentos: mainId,
        })
            .then(response => {
                response = response.data
                const horariosArray = response[0].horarios.split(",");
                const d = new Date();
                let diasemana
                if (d.getDay() == 0) {
                    diasemana = horariosArray[6]
                } else {
                    diasemana = horariosArray[d.getDay() - 1]
                }
                if (diasemana == "Fechado") {
                    setStatusLoja("fechado")
                } else {
                    var unix = Math.round(+new Date() / 1000);
                    const horarios = diasemana.split(" às ");
                    const horario1 = horarios[0].split(":");
                    var unix1 = Math.round(+new Date(d.getFullYear(), d.getMonth(), d.getDate(), horario1[0], horario1[1]) / 1000);
                    const horario2 = horarios[1].split(":");
                    var unix2 = Math.round(+new Date(d.getFullYear(), d.getMonth(), d.getDate(), horario2[0], horario2[1]) / 1000);

                    if (parseInt(horario2[0]) < parseInt(horario1[0]) || (horarios[0] == "00:00" && horarios[1] == "00:00")) {
                        unix2 += 86400
                    }

                    const arrTime = Cookies.get("tD").split("(")
                    const tempo = arrTime[1].replaceAll(")", "")

                    if (unix > unix1 && unix < unix2) {
                        setStatusLoja("aberto")
                    } else {
                        setStatusLoja("fechado")
                    }
                }

            })
            .catch(error => console.log("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
    }

    useEffect(() => {
        if (update.includes("PDV")) {
            setOpenModal(true)
            setModalPdv(true)
        } else if (update.includes("estoque")) {
            const arrClr = update.split("___")
            if (arrClr[2] === "true") {
                setIsRunning(true)
            }
            getProdutos()
        } else if (update.includes("STPG")) {
            const arrClr = update.split("___")
            setCurrentPage(arrClr[1])
        } else if (update.includes("UPDTCLP")) {
            const arrClr = update.split("___")
            setColorPrimary(arrClr[1])
        } else if (update.includes("UPDTNTF")) {
            return
        } else {
            const arrAlert = update.split("___")
            handleAlert(arrAlert[2], arrAlert[1])
        }
    }, [update]);

    const getProdutos = () => {
        const urlBase = Cookies.get('UBs');
        axios.post(`${urlBase}/produtos-inventario.php?idestabelecimentos=1`, {
            idestabelecimentos: "1"
        })
            .then(response => {
                response = response.data
                if (response) {
                    for (const produto of response) {
                        if (Cookies.get('eMA') === "true") {
                            const estoqueMinimo = parseInt(Cookies.get('eMAv'))
                            if (parseInt(produto.estoque) <= estoqueMinimo) {
                                setIsEstoqueMinimo(true)
                                return
                            }
                        } else {
                            setIsEstoqueMinimo(false)
                            setIsRunning(false)
                        }
                    }
                    setIsEstoqueMinimo(false)
                }
            })
    }

    const copyToClipboard = (text) => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text)
                .then(() => {
                    setOpenModal(false);
                    handleAlert("Link copiado com sucesso.", "success")
                })
                .catch(err => {
                    handleAlert("Não foi possivel copiar o link", "error")
                });
        } else {
            const textArea = document.createElement('textarea');
            textArea.value = text;
            document.body.appendChild(textArea);
            textArea.select();
            try {
                document.execCommand('copy');
                handleAlert("Link copiado com sucesso.", "success")
            } catch (err) {
                handleAlert("Não foi possivel copiar o link", "error")
            }
            document.body.removeChild(textArea);
        }
    }

    const handleAlert = (mensagem, tipo) => {
        if (mensagem) {
            const id = new Date().getTime();
            const newAlert = { id, mensagem, tipo };
            setAlerts(prevAlerts => [...prevAlerts, newAlert]);

            setTimeout(() => {
                setAlerts(prevAlerts => prevAlerts.filter(alert => alert.id !== id));
            }, 5000);
        }
    };

    const handleCloseAlert = (id) => {
        setAlerts(prevAlerts => prevAlerts.filter(alert => alert.id !== id));
    };

    const getColorAlert = (tipoAlerta) => {
        switch (tipoAlerta) {
            case "success":
                return "#33550F";
            case "info":
                return "#464545";
            case "warning":
                return "#464545";
            case "error":
                return "#333";

        }
    }

    const getBackgroundAlert = (tipoAlerta) => {
        switch (tipoAlerta) {
            case "success":
                return "#F4F9EC";
            case "info":
                return "#ECF0F9";
            case "warning":
                return "#fffae6";
            case "error":
                return "#FFE9E6";

        }
    }

    const handleCopy = () => {
        copyToClipboard(urlBase.replaceAll("/api", ""));
    };

    const handleOpenPerfil = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePerfil = () => {
        setAnchorEl(null);
    };

    const handleSuporte = () => {
        handleClosePerfil()
        changePage("suporte")
    };

    const handleConfiguracoes = () => {
        handleClosePerfil()
        changePage("configuracoes")
    };

    const handleModalPdv = () => {
        setOpenModal(true)
        setModalPdv(true)
    };

    const handleImpressora = () => {
        setImpressora(!impressora)
        Cookies.set('IAt', !impressora, { expires: 7 });
    };

    const handleVolume = () => {
        setVolume(!volume)
        Cookies.set('Aud', !volume, { expires: 7 });
    };

    const handleLogout = () => {
        Cookies.get("iSpA") == "true" ? Cookies.set('DSlP', true, { expires: 7 }) : console.log(":..")
        Cookies.remove('dsEst');
        Cookies.remove('emEst');
        Cookies.remove('endEst');
        Cookies.remove('fMsP');
        Cookies.remove('hREst');
        Cookies.remove('iEst');
        Cookies.remove('nmEst');
        Cookies.remove('txEst');
        Cookies.remove('tEst');
        Cookies.remove('tsEst');
        Cookies.remove('UBs');
        Cookies.remove('FTs');
        Cookies.remove('isLogged');
        Cookies.remove('isStore');
        navigate("/estabelecimento/login", { replace: true, state: { page: "home" } });
    };

    const handleBaixarPDV = () => {
        setLoadingPdv(true);
        axios.post('https://mely.online/template/download-pdv.php', {
            idestabelecimentos: "1"
        }, {
            responseType: 'blob',
            onDownloadProgress: (progressEvent) => {
                const total = progressEvent.total;
                const current = progressEvent.loaded;
                const percentCompleted = Math.round((current / total) * 100);
                setProgress(percentCompleted);
            }
        })
            .then(response => {
                const blob = new Blob([response.data], { type: 'application/zip' });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = `pdv-mely.zip`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                setLoadingPdv(false);
            })
            .catch(error => {
                setLoadingPdv(false);
            });
    }

    return (
        <>
            <AppBar position="absolute" style={{ backgroundColor: `#ededf6`, color: "#FFF", fontWeight: "bold", boxShadow: "none", fontFamily: "roboto", height: 75, display: "flex", justifyContent: "center" }}>
                <Toolbar>

                    {isMobile ?
                        <>
                            <IconButton size="large" color="inherit" style={{ color: "#2B3AE7" }} onClick={() => setOpen(true)}>
                                <MenuOutlined style={{ fontSize: 42 }} />
                            </IconButton>
                            <img src="https://elysolucoestecnologicas.com.br/images/ely-logo-blue.png" style={{ width: 100, marginLeft: "auto" }} />
                        </>
                        :
                        <>
                            <img src="https://elysolucoestecnologicas.com.br/images/ely-logo-blue.png" style={{ width: 100 }} />
                            <Button variant="outlined" style={{ textTransform: 'none', marginLeft: "auto", background: currentPage === "home" ? "#8e94f1" : "", color: currentPage === "home" ? "#fff" : "", border: currentPage === "home" ? "none" : "" }} onClick={() => changePage("home")}>
                                Home
                            </Button>
                            <Button variant="outlined" style={{ textTransform: 'none', marginLeft: 10, background: currentPage === "sobre" ? "#8e94f1" : "", color: currentPage === "sobre" ? "#fff" : "", border: currentPage === "sobre" ? "none" : "" }} onClick={() => changePage("sobre")}>
                                Sobre nós
                            </Button>
                            <Button variant="outlined" style={{ textTransform: 'none', marginLeft: 10, background: currentPage == "omnichannel" || currentPage == "discador" || currentPage == "agente" ? "#8e94f1" : "", color: currentPage == "omnichannel" || currentPage == "discador" || currentPage == "agente" ? "#fff" : "", border: currentPage == "omnichannel" || currentPage == "discador" || currentPage == "agente" ? "none" : "" }} onClick={handleOpenSolucoes} endIcon={<ExpandMoreOutlined />}>
                                Soluções
                            </Button>
                            <Menu
                                anchorEl={anchorEl}
                                open={openSolucoes}
                                onClose={handleCloseSolucoes}
                                PaperProps={{
                                    style: {
                                      boxShadow: 'none',
                                      marginTop: '10px',
                                    }
                                  }}
                            >
                                <MenuItem style={{ fontWeight: "bold", fontSize: 14, marginLeft: 8, marginRight: 8, background: currentPage === "omnichannel" ? "#8e94f1" : "", color: currentPage === "omnichannel" ? "#fff" : "#1C204A", borderRadius: 6 }} onClick={() => changePage("omnichannel")}>Omnichannel</MenuItem>
                                <MenuItem style={{ fontWeight: "bold", fontSize: 14, margin: 8, background: currentPage === "discador" ? "#8e94f1" : "", color: currentPage === "discador" ? "#fff" : "#1C204A", borderRadius: 6 }} onClick={() => changePage("discador")}>Discador</MenuItem>
                                <MenuItem style={{ fontWeight: "bold", fontSize: 14, marginLeft: 8, marginRight: 8, background: currentPage === "agente" ? "#8e94f1" : "", color: currentPage === "agente" ? "#fff" : "#1C204A", borderRadius: 6 }} onClick={() => changePage("agente")}>Agente Virtual</MenuItem>
                            </Menu>
                            <Button variant={currentPage === "contato" ? "outlined" : "contained"} style={{ textTransform: 'none', marginLeft: 10, background: currentPage === "contato" ? "#8e94f1" : "", color: currentPage === "contato" ? "#fff" : "", border: currentPage === "contato" ? "none" : "" }} onClick={() => changePage("contato")} startIcon={<PhoneOutlined />}>
                                Contato
                            </Button>
                        </>
                    }
                </Toolbar>
            </AppBar>

            {isMobile &&
                <Drawer open={open} onClose={toggleDrawer(false)}>
                    <div style={{ display: "flex", alignItems: "center", color: "#999", fontSize: 12, fontWeight: "400" }}>
                        <img src="https://elysolucoestecnologicas.com.br/images/ely-logo-blue.png" style={{ width: 100, marginLeft: 20 }} />
                    </div>
                    <List style={{ width: 315, padding: 20 }}>
                        <ListItem button style={{ background: currentPage === "home" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("home")}>
                            <ListItemText primary="Home" primaryTypographyProps={{ color: currentPage === "home" ? "#2B3AE7" : "#464545", fontWeight: "400" }} />
                        </ListItem>
                        <ListItem button style={{ background: currentPage === "sobre" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("sobre")}>
                            <ListItemText primary="Sobre nós" primaryTypographyProps={{ color: currentPage === "sobre" ? "#2B3AE7" : "#464545", fontWeight: "400" }} />
                        </ListItem>
                        <Accordion>
                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                <ListItemText primary="Soluções" primaryTypographyProps={{ color: "#464545", fontWeight: "400" }} />
                            </AccordionSummary>
                            <AccordionDetails>
                                <ListItem button style={{ background: currentPage === "omnichannel" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("omnichannel")}>
                                    <ListItemText primary="Omnichannel" primaryTypographyProps={{ color: currentPage === "omnichannel" ? "#2B3AE7" : "#464545", fontWeight: "400" }} />
                                </ListItem>
                                <ListItem button style={{ background: currentPage === "discador" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("discador")}>
                                    <ListItemText primary="Discador" primaryTypographyProps={{ color: currentPage === "discador" ? "#2B3AE7" : "#464545", fontWeight: "400" }} />
                                </ListItem>
                                <ListItem button style={{ background: currentPage === "agente" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("agente")}>
                                    <ListItemText primary="Agente Virtual" primaryTypographyProps={{ color: currentPage === "agente" ? "#2B3AE7" : "#464545", fontWeight: "400" }} />
                                </ListItem>
                            </AccordionDetails>
                        </Accordion>
                        <div style={{ height: 6 }} />
                        <Divider />
                        <div style={{ height: 24 }} />
                        <ListItem button style={{ background: currentPage === "contato" ? "#F2F2F2" : "#2B3AE7", borderRadius: 6 }} onClick={() => changePage("contato")}>
                            <PhoneOutlined style={{ marginRight: 14, color: currentPage === "contato" ? "#2B3AE7" : "#fff" }} />
                            <ListItemText primary="Contato" primaryTypographyProps={{ color: currentPage === "contato" ? "#2B3AE7" : "#fff" }} />
                        </ListItem>
                    </List>
                </Drawer>
            }


        </>
    );
};

export default SideMenu;
