import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import useMediaQuery from '@mui/material/useMediaQuery';
import LoadingComponent from '../components/LoadingComponent';

import { ThemeProvider } from '@mui/material/styles';
import { createNewTheme } from '../functions/utils';
import { Button, Card, CardContent, CardHeader, Divider, Grid, List, ListItem, ListItemButton, ListItemText, TextField, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import { Alert } from '@mui/material';
import { DragIndicator, PhoneOutlined, WhatsApp } from '@mui/icons-material';

function Contato({ onChange, update, colorPrimary, colorSecondary, colorTertiary }, props) {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [tipoSuporte, setTipoSuporte] = useState(0);
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [loading, setLoading] = useState(false);

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const handleSuporteChange = (event, newValue) => {
    setTipoSuporte(newValue);
  };

  const handleNome = (event) => {
    setNome(event.target.value);
  };

  const handleMensagem = (event) => {
    setMensagem(event.target.value);
  };

  const handleEmail = () => {
    handleOpenLoading()
    if (!nome) {
      handleCloseLoading()
      update("", `${Date.now()}___error___Por favor preencha seu nome.`)
      return
    }
    if (!mensagem) {
      handleCloseLoading()
      update("", `${Date.now()}___error___Por favor preencha a mensagem com sua dúvida para nosso suporte entrar em contato.`)
      return
    }
    const post_url = "https://mely.online/api/suporte-email.php";
    const json = JSON.stringify({
      nome: nome,
      email: email,
      mensagem: mensagem
    });

    axios.post(post_url, json)
      .then(resp => {
        const response = resp.data
        if (response) {
          if (response.resposta === "success") {
            handleCloseLoading()
            update("", `${Date.now()}___success___Sua solicitação de suporte foi enviada com sucesso.`)
            setMensagem("")
            setNome("")
            return
          }
        }
        handleCloseLoading()
        update("", `${Date.now()}___error___Não foi possivel enviar a sua solicitação de suporte. Por favor verifique sua conexão e tente novamente.`)
      })
      .catch(error => {
        handleCloseLoading()
        update("", `${Date.now()}___error___Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.`)
      });

  };

  const copyToClipboard = (text) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text)
        .then(() => {
          update("", `${Date.now()}___success___Número copiado com sucesso.`)
        })
        .catch(error => {
          update("", `${Date.now()}___error___Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.`)
        });
    } else {
      const textArea = document.createElement('textarea');
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand('copy');
        update("", `${Date.now()}___success___Número copiado com sucesso.`)
      } catch (err) {
        update("", `${Date.now()}___error___Não foi possivel copiar o número`)
      }
      document.body.removeChild(textArea);
    }
  }

  const handleCopy = (text) => {
    copyToClipboard(text)
  };

  const handleWhatsapp = (text) => {
    window.open(`https://wa.me/55${text}?text=Olá,%20preciso%20de%20ajuda%20com%20o%20mely!`, '_blank');
    copyToClipboard(text)
  };

  useEffect(() => {
    const email = Cookies.get('emEst');
    setEmail(email)
  }, []);

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <LoadingComponent open={loading} />

      <div style={{ height: 100 }} />
      <Typography style={{ textAlign: "center", fontFamily: "roboto regular", fontSize: 64, fontWeight: "bold", color: "#2B3AE7" }}>
        Contato
      </Typography>

      <Typography style={{ fontFamily: "roboto light", textAlign: "center", fontSize: 33, color: "#ABACBA" }}>
        Preencha as informações e entraremos em contato com você!
      </Typography>

      <div style={{ height: 100 }} />

      <div style={{ padding: 26 }}>
        <div style={{ background: "#F6F6F9", borderRadius: 24 }}>
          <Grid container spacing={2} style={{ padding: 32 }}>

            <Grid item xs={12} sm={12} md={12} style={{ paddingTop: 0 }}>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                    Nome <b style={{ color: "#A51D1D" }}>*</b>
                  </Typography>
                  <div style={{ height: 4 }}></div>
                  <TextField
                    fullWidth
                    hiddenLabel
                    // value={nome}
                    // onChange={handleNome}
                    variant="outlined"
                    size="large"
                    placeholder="Insira o seu nome"
                    sx={{ background: "#fff" }}
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                    E-mail <b style={{ color: "#A51D1D" }}>*</b>
                  </Typography>
                  <div style={{ height: 4 }}></div>
                  <TextField
                    fullWidth
                    hiddenLabel
                    // value={nome}
                    // onChange={handleNome}
                    variant="outlined"
                    size="large"
                    placeholder="Insira o seu e-mail"
                    sx={{ background: "#fff" }}
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <div>
                    <Typography style={{ float: "left", fontSize: "14px", fontWeight: "bold", color: "#1B1B1B" }}>
                      Mensagem
                    </Typography>

                    <Typography style={{ float: "right", fontSize: "12px", color: "#999" }}>
                      0/140
                    </Typography>
                  </div>

                  <TextField
                    fullWidth
                    hiddenLabel
                    // value={comentario}
                    // onChange={handleComentarioChange}
                    variant="outlined"
                    size="large"
                    inputProps={{ maxLength: 140 }}
                    placeholder=""
                    multiline
                    rows={4}
                    sx={{ background: "#fff" }}
                    type="text"
                  />

                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Button variant={"contained"} size="large" style={{ textTransform: 'none', margin: "auto", fontFamily: "roboto light", fontWeight: "bold", minWidth: 140 }}>
                    Enviar
                  </Button>
                </Grid>
              </Grid>


            </Grid>
          </Grid>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default Contato;